
















































































































































import {Component, Watch, Mixins} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import FilterToggle from '@/components/FilterToggle.vue'
import FilterDapp from '@/components/filters/FilterDapp.vue'
import {Dapp} from '@/model/resource/Dapp'
import {DappCollection} from '@/model/collection/DappCollection'
import {ListDappSchema} from '@/schema/resource/Dapp/ListDappSchema'
import {ExportDappSchema} from '@/schema/resource/Dapp/ExportDappSchema'
import {DappDailyCache} from '@/model/resource/DappDailyCache'

@Component({
  components: {FilterToggle, FilterDapp},
})
export default class ListDappView extends Mixins(MixinRouteMatch) {
  schema = new ListDappSchema()
  collection = new DappCollection()
  filterOpen = false

  async created() {
    this.collection.inActiveDevelopment = null
    this.collection.hasContract = false
    if (this.hasQueryParams) {
      this.updateObjectFromRoute(this.collection)
    }
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }

  @Watch('collection', {deep: true})
  collectionEvent() {
    this.updateRouteFromObject(this.collection)
  }

  goToPersistView(item: Dapp) {
    this.$nav.pushByName('editDapp', item.$id)
  }

  async removeItem(item: Dapp) {
    await this.$dialog.remove(item)
    await item.removeDapp()
    await this.collection.queryAsPage()
  }

  async downloadXlsx() {
    const {ascending, orderBy, page, limit, ...params} = this.collection.params

    const coll = new DappCollection().clearFilters().addFilter(params)

    await coll.listExportDapp()
    this.$xlsx.downloadFromSchema(coll.items, new ExportDappSchema())
  }

  async resyncTxData() {
    await this.$dialog.resyncTxData()
    await this.$await.run('resyncTxData', async () => {
      await DappDailyCache.resyncTxData()
      this.$toast.info(
        'system.info.resyncTxDataBody',
        'system.info.resyncTxDataTitle'
      )
      await this.$utils.sleep(10000)
    })
  }
}
